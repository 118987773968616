import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
import getDataFromResponse from "../utils/getDataFromResponse";

const headers = {
  headers: {
    authorization: `Bearer ${window.localStorage.getItem("token")}`,
  }
}

const fetchUsers = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  searchText = "",
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    search_text: searchText,
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/users`,
    query: payload,
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      console.log(`Error: Failed to fetch Users, ${error}`);
    });
};

const fetchMyProfile = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/users/my_details`,
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error?.response?.data?.error);
    })
};

const updatePassword = (payload = EMPTY_OBJECT) => {
  // debugger
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/users/update_password`
  });

  return axios
    .post(url, payload, headers)
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
    });
};

const updateProfile = (payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/users/update_user`
  });
  return axios
    .post(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      toast.error(error?.response?.data?.error);
    });
};

const fetchFriendList = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/friend/list`,
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error?.response?.data?.error);
    });
};

const fetchUserProfile = (id) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/users/${id}`,
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      console.log(error.response, 'error')
    });
};
const fetchCountries = () => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/config/getAllCountry`,
  });
  return axios
    .get(url, headers)
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error?.response?.data?.error);
    });
};

export default {
  fetchUsers,
  fetchMyProfile,
  updatePassword,
  updateProfile,
  fetchCountries,
  fetchFriendList,
  fetchUserProfile,
};
