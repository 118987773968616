import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import { SalesSummary, Transactions, SalesFilters } from "./components";
import { PageHeader } from "../../components/molecules";

// Helpers
import { createPageHeaderProps } from "./helpers";

// Constants
import { EMPTY_OBJECT } from "../../constants";

// Styles
import styles from "./sales.module.scss";
import userService from "../../services/userService";
import { addUser, getAllCountries } from "../../redux";

function Dashboard() {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState(EMPTY_OBJECT);
  const [filtersVisible, setFiltersVisibility] = useState(false);

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
  };

  const handleCloseFilters = () => {
    setFiltersVisibility(false);
  };

  const handleOpenFilters = () => {
    setFiltersVisibility(true);
  };

  const pageHeaderProps = createPageHeaderProps({
    actionHandler: handleOpenFilters,
  });
  const fetchCountries = () => {
    userService
      .fetchCountries()
      .then((response) => {
        dispatch(getAllCountries(response));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <div className={styles.pageContainer}>
      <PageHeader {...pageHeaderProps} />
      <SalesSummary appliedFilters={filters} />
      <Transactions appliedFilters={filters} />
      {filtersVisible && (
        <SalesFilters
          visible={filtersVisible}
          onOk={handleApplyFilters}
          onCancel={handleCloseFilters}
        />
      )}
    </div>
  );
}

export default Dashboard;
