import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  user: {},
  friendList: [],
  countries: [],
};

const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    addUser: (state, action = {}) => {
      const { payload: user } = action;
      state.user = user;
    },
    getAllCountries: (state, action = {}) => {
      const { payload: countries } = action;
      state.countries = countries;
    },
    addFriendList: (state, action = {}) => {
      const { payload: users } = action;
      state.friendList = users;
    },
  },
});

const { addUser, addFriendList, getAllCountries } = userSlice.actions;
const userReducer = userSlice.reducer;

export { addUser, addFriendList, getAllCountries, userReducer };
export default userSlice;
