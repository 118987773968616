// Lodash
import _map from "lodash/map";
import _join from "lodash/join";

// Components
import Status, { STATUS_TYPES } from "../../../components/molecules/status";
import Button, { BUTTON_TYPES } from "../../../components/atoms/button";
import { EditOutlined } from "../../../components/atoms/icon";

// Readers
import { packageReader, sportReader } from "../../../readers";

// Styles
import styles from "../packages.module.scss";

const getSportNames = (sports) =>
  _map(sports, (sport) => sportReader.name(sport));

const createColumns = ({ onEdit }) => {
  return [
    {
      title: "Name",
      width: "200px",
      render: (_, packageDetails) => {
        return packageReader.packageName(packageDetails);
      },
    },
    {
      title: "Address",
      render: (_, packageDetails) => {
        return packageReader.address(packageDetails);
      },
    },
    {
      title: "Price",
      render: (_, packageDetails) => {
        return packageDetails?.totalPrice;
      },
    },
    {
      title: "Final Price",
      render: (_, packageDetails) => {
        return packageReader.price(packageDetails);
      },
    },
    {
      title: "Sports",
      render: (_, packageDetails) => {
        const sports = packageReader.sports(packageDetails);
        const sportNames = getSportNames(sports);
        return _join(sportNames, ", ");
      },
    },
    {
      title: "Status",
      width: "100px",
      render: (_, packageDetails) => {
        const status = packageReader.isActive(packageDetails)
          ? STATUS_TYPES.ACTIVE
          : STATUS_TYPES.INACTIVE;
        return <Status status={status} />;
      },
    },
    {
      title: "Actions",
      render: (_, packageDetails) => {
        return (
          <>
            <Button
              type={BUTTON_TYPES.LINK}
              onClick={(event) => {
                event.stopPropagation();
                onEdit(packageDetails);
              }}
              className={styles.icon}
            >
              <EditOutlined />
            </Button>
          </>
        );
      },
    },
  ];
};

export { createColumns };
