const getFormTitle = (editMode = false, packageName) => {
  if (!editMode) {
    return "Create New Package";
  }
  return `Edit Package - ${packageName}`;
};

 const vat_calculate = (amount, vat) => {
  const per = Number(vat);
  let result = (amount * per) / 100;
  return Number(result).toFixed(2);
};

 const vat_calculateFun = (val, discountResponse) => {
  let amount = Number(val?.subTotal);
  if (discountResponse) {
    amount = amount - discountResponse?.savePrice;
  }

  let result = vat_calculate(amount, val?.vat);
  return result;
};

 const  total_calculate = amount => {
  const per = 5;
  let result = (amount * per) / 100;
  let total = amount + result;
  return Number(total).toFixed(2);

};
const calculateNetPriceAndTax=(totalPrice, taxRate = 5) =>{
  const taxMultiplier = 1 + taxRate / 100; // Convert tax rate to multiplier (e.g., 5% -> 1.05)
  const netPrice = totalPrice / taxMultiplier; // Calculate net price
  const tax = totalPrice - netPrice; // Calculate tax

  return {
      netPrice: netPrice.toFixed(2), // Round to 2 decimal places
      tax: tax.toFixed(2) // Round to 2 decimal places
  };
}
export { getFormTitle,vat_calculate,total_calculate,vat_calculateFun ,calculateNetPriceAndTax};
