import axios from "axios";
import queryString from "query-string";

// Constants
import { EMPTY_OBJECT } from "../constants/base.constants";
import { BASE_URL } from "../constants/services";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "../constants/pagination";

// Utils
import { toast } from "react-toastify";
import getDataFromResponse from "../utils/getDataFromResponse";

const fetchPackages = ({
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  searchText = "",
  sortField,
  order,
} = EMPTY_OBJECT) => {
  const payload = {
    page: pageNumber,
    limit: pageSize,
    search_text: searchText,
    sort_field: sortField,
    order_by: order === "decend" ? "desc" : order === "ascend" ? "asc" : "",
    is_active: "all",
  };
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/packages`,
    query: payload,
  });
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      console.log(`Error: Failed to fetch Packages, ${error}`);
    });
};

const createPackage = (payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/packages`,
  });
  return axios
    .post(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const updatePackage = (id, payload = EMPTY_OBJECT) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/packages/${id}`,
  });
  console.log({payload})
  return axios
    .put(url, payload, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => getDataFromResponse(response))
    .catch((error) => {
      toast.error(error.response.data?.error);
    });
};

const fetchPackageDetails = (id) => {
  const url = `${BASE_URL}/packages/${id}`;
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response));
};

const fetchPackagePurchaseHistory = (id, searchText) => {
  const url = queryString.stringifyUrl({
    url: `${BASE_URL}/transactions`,
    query: {
      package_id: id,
      search_text: searchText,
    },
  });
  return axios
    .get(url, {
      headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
      },
    })
    .then((response) => getDataFromResponse(response));
};

export default {
  fetchPackages,
  createPackage,
  updatePackage,
  fetchPackageDetails,
  fetchPackagePurchaseHistory,
};
