
import axios from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

// Utils
import { BASE_URL } from "../../../constants/services";
import { getDataFromResponse } from "../../../utils";

const headers = {
    headers: {
        authorization: `Bearer ${window.localStorage.getItem("token")}`,
    }
}

async function makeOption(array) {
    return array.map((user) => ({
        label: `${user.firstName} ${user?.lastName ? user.lastName : null} (${user.username})`,
        value: user._id,
    }))
}

const fetchUserList = (search_text) => {
    const payload = {
        page: 1,
        limit: 10,
        search_text,
        sort_field: "username",
        order_by: "asc",
        role: "user"
    };
    const url = queryString.stringifyUrl({
        url: `${BASE_URL}/users/freelance-venue`,
        query: payload,
    });

    return axios
        .get(url, headers)
        .then((response) => {
            let data = getDataFromResponse(response)
            return makeOption(data.docs)
        })
        .catch((error) => {
            toast.error(error?.response?.data?.error);
        });
};

const fetchActivityList = (search_text, vendorList = []) => {
    const payload = {
        page: 1,
        limit: 10,
        search_text,
        order_by: "asc",
        sort_field: "name",
        self: true,
        future: true,
    };
    const url = queryString.stringifyUrl({
        url: `${BASE_URL}/events/activities`,
        query: payload,
    });

    return axios
        .get(url, headers)
        .then((response) => {
            let data = getDataFromResponse(response)
            if (data.docs.length === 0) return [{
                label: `No record found`,
                disabled: true,
                value: null,
            }]
            else return data.docs.map((item) => ({
                label: `${item.name}`,
                value: item._id,
            }))
        })
        .catch((error) => {
            toast.error(error?.response?.data?.error);
        });
}

const fetchPitchList = (search_text, vendorList = []) => {
    const payload = {
        page: 1,
        limit: 10,
        search_text,
        order_by: "asc",
        sort_field: "name",
        self: true
    };
    const url = queryString.stringifyUrl({
        url: `${BASE_URL}/pitch/all`,
        query: payload,
    });

    return axios
        .get(url, headers)
        .then((response) => {
            let data = getDataFromResponse(response)
            if (data.docs.length === 0) return [{
                label: `No record found`,
                disabled: true,
                value: null,
            }]
            else return data.docs.map((item) => ({
                label: `${item.name}`,
                value: item._id,
            }))
        })
        .catch((error) => {
            toast.error(error?.response?.data?.error);
        });

}

const fetchPackageList = (search_text, vendorList = []) => {
    const payload = {
        page: 1,
        limit: 10,
        search_text,
        order_by: "asc",
        sort_field: "name",
        self: true,
        future: true,
    };
    const url = queryString.stringifyUrl({
        url: `${BASE_URL}/packages/all_list`,
        query: payload,
    });

    return axios
        .get(url, headers)
        .then((response) => {
            let data = getDataFromResponse(response)
            if (data.length === 0) return [{
                label: `No record found`,
                disabled: true,
                value: null,
            }]
            else return data.map((item) => ({
                label: `${item.package_name}`,
                value: item._id,
            }))
        })
        .catch((error) => {
            toast.error(error?.response?.data?.error);
        });
}


export { fetchUserList, fetchActivityList, fetchPitchList, fetchPackageList };
