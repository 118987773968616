const { REACT_APP_URL, NODE_ENV } = process.env
let URL = "https://api.dudiapp.com/v2";
if (NODE_ENV === "development") {
    URL = "http://localhost:3000/v2";
}
if (REACT_APP_URL === "dev") {
    // URL = "https://api-dev.dudiapp.com/v2";
    URL = "http://localhost:3000/v2";

}
const BASE_URL = URL
export { BASE_URL };
