import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Components
import { Menu, Dropdown, Tooltip } from "antd";

import { MessageOutlined, } from "@ant-design/icons";

// Readers
import adminReader from "../../../readers/admin";

// Icons
import Avatar from "../../atoms/avatar";

// Services
import { sportsService } from "../../../services";
import userService from "../../../services/userService";

// Redux
import { addSports, addUser, getAllCountries } from "../../../redux";

// Styles
import styles from "./navbar.module.scss";

function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user.user);
  const sportsList = useSelector((state) => state?.sports?.items);
  const profileImageUrl = adminReader.profileImageUrl(user);

  const handleSingOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    if (sportsList?.length === 0 || !sportsList?.length)
      sportsService
        .fetchAllSports()
        .then((response) => {
          const sports = response
          dispatch(addSports(sports));
        })
        .catch((error) => {
          console.log(error);
          dispatch(addSports([]));
        });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !user?.username) {
      userService
        .fetchMyProfile()
        .then((response) => {
          dispatch(addUser(response));
          localStorage.setItem("signedIn", "true");
          localStorage.setItem("token", token);
          localStorage.setItem("userID", response?._id);
        })
        .catch((error) => {
          console.log("Error fetching roles", error);
        });
        userService.fetchCountries()
        .then((response) => {
          dispatch(getAllCountries(response));
        })
        .catch((error) => {
          console.log(error);
        
        });
    }
  }, [])

  const menu = (
    <Menu className={styles.dropdownMenu}>
      <Menu.Item key="profileFreelancer">
        <Link to="/profile">Profile</Link>
      </Menu.Item>
      <Menu.Item onClick={handleSingOut} key="signOut">
        Sign Out
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.container}>
      <div className={styles.boxcontainer}>
        <div className="row JCB AIC">

          <Tooltip title="Coming soon">
            <MessageOutlined />
          </Tooltip>

          <div className="row AIC">
            <div className={styles.userDetail}>
              <div className={styles.fullname}>{user.firstName} {user.lastName}</div>
              <div className={styles.username}>{user.username}</div>
            </div>

            <Dropdown overlay={menu}>
              <Avatar src={profileImageUrl} />
            </Dropdown>

          </div>

        </div>

      </div>
    </div>
  );
}

export default Navbar;
