const ADDRESS = "ADDRESS";
const DESCRIPTION = "DESCRIPTION";
const HOT_DEAL = "HOT_DEAL";
const LOCATION = "LOCATION";
const LATITUDE = "LATITUDE";
const LONGITUDE = "LONGITUDE";
const NAME = "NAME";
const PACKAGE_TYPE = "PACKAGE_TYPE";
const PHOTOS = "PHOTOS";
const PRICE = "PRICE";
const OLD_PRICE = "OLD_PRICE";
const QUANTITY = "QUANTITY";
const SPORTS = "SPORTS";
const STATUS = "STATUS";
const EXPIRE_DATE = "EXPIRE_DATE";
const TAX_PRICE = "TAX_PRICE";
const TOTAL_PRICE = "TOTAL_PRICE";

const FORM_FIELDS = {
  ADDRESS,
  DESCRIPTION,
  HOT_DEAL,
  LOCATION,
  LATITUDE,
  LONGITUDE,
  NAME,
  PACKAGE_TYPE,
  PHOTOS,
  PRICE,
  OLD_PRICE,
  QUANTITY,
  SPORTS,
  STATUS,
  EXPIRE_DATE,
  TAX_PRICE,
  TOTAL_PRICE,
};

export { FORM_FIELDS };
